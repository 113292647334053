<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <div class="form-title-group">
                    <div class="menu-active-mark" style="margin-top: 10px;"/>
                    <div class="form-title"><h1>企業紐付け</h1></div>
                  </div>
                  <div class="menu-bar"/>
                  <p class="form-text">SES MEDIA運営事務局により、あなたの会社の登録が行われている可能性があります。登録が行われている場合には紐付け申請を行っていただき、企業情報が未登録の場合には、企業登録をお願いいたします。企業紐付けは申請後、申請者のメールアドレスドメインを元に運営事務局で確認し、問題なければ5営業日以内に反映いたします。</p>
                  <div v-if="hasValidationError || isCompanyDoesNotExist" class="error-area">
                    <p v-for="item in allValidationError" :key="item.validationText">
                      ・{{item.validationText}}
                    </p>
                    <p v-if="isCompanyDoesNotExist">・該当する企業情報は登録されていません。</p>
                  </div>
                  <form @submit.prevent="search">
                    <div class="form-group">
                      <label>会社名検索</label>
                      <input type="text" class="form-control" placeholder="株式会社ユルリカ" v-model="searchText">
                    </div>
                    <div class="form-group" style="display: block; border: none;">
                      <button type="submit" class="btn-md pop-login form-button full-width" style="width: 100%; margin: 10px 0;" :disabled="!searchText" @click="search">検索</button>
                      <router-link class="btn-md full-width pop-login mypage-menu weakbtn login-remarks" style="font-size: 12px; font-weight: 500; padding: 5px 0; text-align: left;" to="/registercompany">紐付け企業がない時はまず企業登録から</router-link>
                    </div>
                  </form>
                  <div v-for="data of companyList" :key="data.id" class="row">
                    <div class="_large_jb_list72 shadow_0">
                      <div class="_large_jb_header">
                        <div class="_list_110">
                          <div class="_list_110_caption">
                            <!--書き換えが必要-->
                            <div class="company-top-wrap">
                              <div v-if="data.recruitmentStatus !== ''" class="company-top">
                                <div v-if="data.recruitmentStatus === 1" class="jb_types parttime position_setting">パートナー企業積極募集中！</div>
                                <div v-if="data.recruitmentStatus === 2" class="jb_types parttime position_setting">案件に強いパートナー企業募集中！</div>
                                <div v-if="data.recruitmentStatus === 3" class="jb_types parttime position_setting">要員に強いパートナー企業募集中！</div>
                                <div v-if="data.recruitmentStatus === 4" class="jb_types parttime position_setting">パートナー企業募集停止中</div>
                              </div>
                            </div>
                            <div class="company-name"><h4 class="_jb_title">{{data.name}}</h4></div>
                            <div class="_emp_jb">所在地：{{data.address}}</div>
                            <div class="_large_jb_body_list_info">
                              <ul>
                                <li v-if="data.foundedYear" class="letter-space">設立：{{data.foundedYear}}年</li>
                                <li v-if="data.capital" class="letter-space">資本金： {{Number(data.capital).toLocaleString()}}円</li>
                                <li v-if="data.employeeNum" class="letter-space">従業員数： {{data.employeeNum}}人</li>
                                <li v-if="data.phoneNumber" class="letter-space">電話番号： {{data.phoneNumber}}</li>
                                <li v-if="data.isBranchOffice" class="letter-space">支店・支社有無：あり</li>
                                <li v-if="data.isDispatchLicense" class="letter-space">一般派遣免許：あり</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="data.companyFeatures.length > 0" class="_large_jb_body2">
                        <div class="_large_jb_body_list_explain">
                          <div class="jb_req_skill">
                            <ul>
                              <li v-for="featureType of getFeatureTypes(data.companyFeatures)" :key="featureType"><span>{{getFeatureText(featureType)}}</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-md full-width pop-login" :disabled="data.userGroup" @click="link(data)">{{data.userGroup ? "管理者設定済み" : "紐付け"}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";

    const featureText = [
        "エンド直案件あり",
        "元請け直案件あり",
        "ロースキル案件あり",
        "自社内・グループ会社内案件あり",
        "社員・プロパー営業あり",
        "直フリーランスの扱いあり",
        "業務系に強い",
        "Web系に強い",
        "ゲーム系に強い"
    ]

    export default {
        name: "LinkListedCompany",
        components: {Footer, Header, MyPageContents},
        mixins: [MyPageMixIn],
        data: function() {
            return {
                searchText: "",
                companyList: [],
                isCompanyDoesNotExist: false
            }
        },
        methods: {
            async search() {
                const response = await this.postApi("company/search", {
                    searchText: this.searchText
                });
                if (response) {
                    this.companyList = response.data;
                    this.isCompanyDoesNotExist = this.companyList.length === 0;
                } else {
                    this.isCompanyDoesNotExist = false;
                }
            },
            async link(data) {
                const response = await this.postApi("company/requestLink", {
                    companyId: data.id
                }, true);
                if (response) {
                    this.pushResult("紐づけ申請しました", "問題がなければ5営業日以内に紐づけされます");
                }
            },
            getFeatureTypes: function(companyFeatures) {
                if (!companyFeatures) {
                    return []
                }
                return companyFeatures.map(companyFeature => Number(companyFeature.feature));
            },
            getFeatureText: function(featureType) {
                return featureText[featureType];
            },
        }
    }
</script>

<style scoped>

</style>
